import { tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
    Order,
    DeliverySlot,
    MenuItem,
    OrderProcessInfo,
    ReservedSlots,
} from "./app.models";
import { Observable, BehaviorSubject, lastValueFrom } from "rxjs";
import { DataService } from "./data.service";
import { environment } from "environments/environment";

declare var _: any;

@Injectable()
export class OrderService {
    isClosed = false;

    order = new Order();

    orderProcessInfo$: Observable<OrderProcessInfo>;
    reservedDeliverySlots$: Observable<ReservedSlots>;
    allMenuItems: MenuItem[];

    closedAfter: Date[];
    closedOn: Date[];
    openOn: Date[];
    noDiscountOn: Date[];
    reservedDeliverySlots: DeliverySlot[];
    noDeliveries: boolean;
    isDebugMode: boolean;
    allowDiscountToday: boolean;

    orderFormIsValid: boolean;

    isReadyToOrder$ = new BehaviorSubject(false);
    isSiteClosed$ = new BehaviorSubject(false);

    constructor(private dataService: DataService) {
        this.orderFormIsValid = false;
        this.closedAfter = [];
        this.closedOn = [];
        this.openOn = [];
        this.noDiscountOn = [];
        this.reservedDeliverySlots = [];
        this.allMenuItems = [];
        this.isDebugMode = false;
        this.allowDiscountToday = false;
        this.getReservedSlots().subscribe();
    }

    getReservedSlots(): Observable<ReservedSlots> {
        return this.dataService.getReservedSlots().pipe(
            tap({
                next: (data: ReservedSlots) => {
                    this.reservedDeliverySlots = data.slots;
                    this.isSiteClosed$.next(data.isSiteClosed);
                },
            })
        );
    }

    public async getOrderProcessInfo(): Promise<OrderProcessInfo> {
        const result = this.dataService.getOrderProcessInfo().pipe(
            tap({
                next: (data: OrderProcessInfo) => {
                    this.allMenuItems = data.menuItems as MenuItem[];
                    this.isDebugMode = data.isDebugMode;
                    this.noDeliveries = data.noDeliveries;
                    this.isSiteClosed$.next(data.isSiteClosed);
                    if (data.closedOn) {
                        this.closedOn = data.closedOn;
                        data.closedOn.forEach((dateString) => {
                            const date = new Date(dateString);
                            if (new Date().getDate() === date.getDate()) {
                                this.isClosed = true;
                                return;
                            }
                        });
                    }
                    if (data.openOn) {
                        this.openOn = data.openOn;
                        data.openOn.forEach((dateString) => {
                            const date = new Date(dateString);
                            if (
                                new Date().getDate() === date.getDate() &&
                                new Date().getTime() > date.getTime()
                            ) {
                                this.isClosed = false;
                                return;
                            }
                        });
                    }
                    if (data.noDiscountOn) {
                        this.noDiscountOn = data.noDiscountOn;
                    }
                    if (data.closedAfter) {
                        this.closedAfter = data.closedAfter;
                    }
                },
                error: (error) => {
                    this.isClosed = true;
                },
            })
        );
        return await lastValueFrom(result);
    }

    getTotalPrice() {
        let totalPrice = 0;
        let itemsDiscountCount = 0;
        let itemsDiscountPrice = 0;
        let foodItemsTotalPrice = 0;
        let foodItemsTotalCount = 0;
        this.order.discountApplied = false;
        this.order.discountPrice = 0;
        this.order.items.forEach((item, id) => {
            const itemPrice = item.getPrice();
            totalPrice += itemPrice;

            if (item.categoryId > 2 && item.categoryId < 12) {
                foodItemsTotalCount += item.count;
                foodItemsTotalPrice += itemPrice;
                if (item.number) {
                    const itemNumber = +(item.number + "").match(/[0-9]*/g)[0];
                    if (itemNumber > 0 && itemNumber < 86) {
                        itemsDiscountPrice += itemPrice * 0.1;
                        itemsDiscountCount += item.count;
                    }
                }
            }
        });
        this.order.totalItemPrice = totalPrice;
        this.order.totalFoodItemCount = foodItemsTotalCount;
        if (this.order.deliver) {
            if (
                this.order.isPostalCodeOutOfZone ||
                totalPrice < environment.deliveryCostOrderAmmountLowerLimit
            ) {
                this.order.applyDeliveryCost = true;
                totalPrice += environment.deliveryCost;
            } else {
                this.order.applyDeliveryCost = false;
            }
        } else {
            this.allowDiscountToday = true;
            const deliveryDate = new Date(this.order.deliveryDate);
            const weekdays = [2, 3, 4];
            // Alleen op dinsdag t/m donderdag
            if (weekdays.indexOf(deliveryDate.getDay()) === -1) {
                this.allowDiscountToday = false;
            } else {
                this.noDiscountOn.forEach((dateString) => {
                    const date = new Date(dateString);
                    if (deliveryDate.getDate() === date.getDate()) {
                        this.allowDiscountToday = false;
                    }
                });

                if (this.allowDiscountToday && itemsDiscountCount > 2) {
                    itemsDiscountPrice = +itemsDiscountPrice.toFixed(2);
                    totalPrice -= itemsDiscountPrice;
                    this.order.discountApplied = true;
                    this.order.discountPrice = itemsDiscountPrice;
                }
            }
        }
        if (this.order.discountApplied) {
            this.order.totalItemPrice -= this.order.discountPrice;
        }
        this.order.totalPrice = +totalPrice.toFixed(2);
    }

    checkIsReadyToOrder() {
        this.isReadyToOrder$.next(false);

        this.getTotalPrice();

        let hasErrors = false;
        if (this.order.items.length === 0) {
            hasErrors = true;
        }
        if (!this.order.deliveryDate || !this.order.deliveryTime) {
            hasErrors = true;
        }
        if (this.order.deliver) {
            if (
                this.order.deliver &&
                this.order.isPostalCodeOutOfZone &&
                (this.order.totalFoodItemCount < 2 ||
                    this.order.totalItemPrice <
                        environment.deliveryCostOrderAmmountLowerLimit)
            ) {
                hasErrors = true;
            }
            if (
                this.order.deliver &&
                !this.order.isPostalCodeOutOfZone &&
                this.order.totalPrice <
                    environment.deliveryCostOrderAmmountLowerLimit
            ) {
                hasErrors = true;
            }
        }

        if (!this.orderFormIsValid) {
            hasErrors = true;
        }

        if (this.order.totalPrice === 0) {
            hasErrors = true;
        }

        if (this.isSiteClosed$.value) {
            hasErrors = true;
        }

        if (hasErrors === true) {
            this.isReadyToOrder$.next(false);
        } else {
            this.isReadyToOrder$.next(true);
        }
    }

    orderMin(oldItem: MenuItem) {
        const orderItem = _.find(this.order.items, (i: MenuItem) => {
            return (
                i.id === oldItem.id &&
                i.selectedOption === oldItem.selectedOption
            );
        });
        if (orderItem.count > 1) {
            orderItem.count--;
        } else {
            const index = this.order.items.indexOf(orderItem);
            this.order.items.splice(index, 1);
        }
        this.checkIsReadyToOrder();
    }
}
