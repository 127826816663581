<app-header></app-header>
<app-nav></app-nav>
<div id="content-wrapper">
  <div class="white-block">
    <img class="picture" src="assets/images/luciano.png" alt="Luciano Colabella" />
    <h1>Over ons</h1>
    <p>
      A Bella Vita is de enige echte Italiaanse keuken bij u thuis!
      <br> Altijd op ambachtelijke wijze met dagverse producten bereid.
      <br> A Bella Vita staat voor de beste kwaliteit en authentieke Italiaanse gerechten.
    </p>
    <p>
      Uw gastheer, Luciano Colabella
    </p>
  </div>

  <div class="white-block">
    <h1>Openingstijden</h1>
    <p>
      Open op dinsdag t/m zondag van 16:30 tot 21:00 uur.<br>
      We bezorgen tot 20:30 uur.<br>
      <br>
      <span class="underline">Op maandag zijn wij gesloten.</span>
    </p>
  </div>

  <div class="white-block">
    <h1>Gewonnen prijzen</h1>
    <img class="picture" src="assets/images/ribbon.png" alt="ribbon" />
    <p>
      <strong>2004</strong>
      <br> 1e en 2e prijs pizza
      <br>
      <br>
      <strong>2009</strong>
      <br> 2e en 3e prijs authentieke Italiaanse specialiteiten
      <br>
      <br>
      <strong>2010</strong>
      <br> KASSA! NPO1
    </p>
  </div>

  <div class="white-block">
    <h1>Contactgegevens</h1>
    <p>
      <strong>Adres:</strong>
      <br>Forelkolk 134
      <br>8017PK
      <br>Zwolle
    </p>
    <p>
      <strong>Telefoon:</strong>
      <br>038 465 44 54
    </p>
  </div>
</div>
<app-footer></app-footer>