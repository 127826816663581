import { Injectable } from "@angular/core";

@Injectable()
export class GlobalFunctionsService {
    constructor() { }

    public stringIsNullOrEmpty(value: string) {
        if (value === undefined || value === null || value === "") {
            return true;
        }
        return false;
    }

    public formatDate(date) {
        const d = new Date(date);
        const year = d.getFullYear();
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();

        if (month.length < 2) {
            month = "0" + month;
        }
        if (day.length < 2) {
            day = "0" + day;
        }
        return [year, month, day].join("-");
    }

    public formatDateString(date) {
        const dateDayString = [
            "Zondag",
            "Maandag",
            "Dinsdag",
            "Woensdag",
            "Donderdag",
            "Vrijdag",
            "Zaterdag"
        ];

        const d = new Date(date);
        const year = d.getFullYear();
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();

        if (month.length < 2) {
            month = "" + month;
        }
        if (day.length < 2) {
            day = "" + day;
        }

        return day + "-" + month + " " + dateDayString[d.getDay()];
    }
}
