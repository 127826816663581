import { Component } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.less"]
})
export class FooterComponent {

  currentYear = (new Date()).getFullYear();

  constructor() { }
}
