<app-header></app-header>
<app-nav></app-nav>
<div id="content-wrapper">
    <div class="white-block">
        <h1>Welkom bij A Bella Vita Pizzeria</h1>
        <img class="picture" src="assets/images/luciano.png" alt="Luciano Colabella" />
        <p>Wij hopen dat u iets vindt wat u zal smaken.</p>
        <p>
            Bekijk voor leuke nieuwtjes en aanbiedingen onze
            <a href="https://www.facebook.com/Abellavita.nl/" target="_blank">Facebook</a> pagina.</p>
        <p>
            Met vriendelijk groet,
            <br>
            <br> Het team van A Bella Vita
        </p>
    </div>
    <div class="white-block">
        <img class="picture" src="assets/images/collegas-gezocht.png" alt="collega's gezocht"
            style="max-width: 300px; width: 50%; float: left;" />
        <p>
            <strong>Heb jij een goed betaald bijbaantje nodig?</strong>
            <br>
            <br>Stuur gerust een mailtje naar <a href="mailto:{{solEmail}}?subject=bijbaantje">{{solEmail}}</a>, loop
            een
            keer langs of bel naar 038 465 44 54.
            <br>
            <br>Je moet minimaal 15 jaar zijn en een paar avonden per week beschikbaar zijn (vanaf 17:00 tot max.
            20:00).
            <br>Heb jij een AM of B rijbewijs? Ook dan kan je bij ons komen werken!
        </p>
    </div>
    <div class="white-block">
        <p>
            <strong>Telefoon:</strong> 038 465 44 54
            <br>
            <strong>Adres:</strong> Forelkolk 134, 8017 PK, Zwolle
        </p>
        <p>
            <strong>Openingstijden:</strong>
            <br>Open op dinsdag t/m zondag van 16:30 tot 21:00 uur.
            <br>We bezorgen tot 20:30 uur.
            <br>
            <!-- <br><strong>Van 28 juli t/m 9 augustus zijn wij, ivm vakantie, gesloten.
                <br>Wij verwelkomen u daarna graag weer.
                <br>
                <br>Arrivederci</strong>
            <br> -->

            <!-- <br><strong>Op 25 december zijn wij, ivm 1e kerstdag, gesloten.</strong>
            <br> -->

        </p>

        <!-- <p>
      <span class="underline">Op maandag zijn wij gesloten, met uitzondering van:</span><br>
      <span> - 2e Paasdag (22-04-2019) van 16:30 t/m 20:00</span><br>
      <span> - 2e Pinksterdag (10-06-2019) van 16:30 t/m 20:00</span>
    </p> -->
    </div>
    <app-menu-folder></app-menu-folder>
</div>
<app-footer></app-footer>
