import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.less"]
})
export class HomeComponent implements OnInit {

    solEmail = "lucia23038@gmail.com";

    constructor() { }

    ngOnInit() {
    }

}
