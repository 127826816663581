<p class="error" *ngIf="orderTimes.length < 2">
    Op dit moment zijn wij (nog) niet geopend.
    <br>U kunt uw bestelling, voor een andere moment, al wel invoeren.
</p>
<p class="small-text">Velden met een * zijn verplicht.</p>
<div id="form-container">
    <div class="form-field">
        <label>Naam *</label>
        <input type="text" [(ngModel)]="order.personInfo.name">
    </div>
    <div class="form-field">
        <label>Telefoonnummer *</label>
        <input type="text" [(ngModel)]="order.personInfo.phone" (blur)="isValidPhone()">
        <div class="validation-message" *ngIf="formValidation.phone?.length > 0">
            {{formValidation?.phone}}
        </div>
    </div>
    <div class="form-field">
        <label>E-mail</label>
        <input type="text" [(ngModel)]="order.personInfo.email" (blur)="isValidEmail()">
        <div class="validation-message" *ngIf="formValidation.email?.length > 0">
            {{formValidation?.email}}
        </div>
        <p class="gray-text no-margin-bottom">
            Uw e-mailadres wordt gebruikt voor het versturen van een orderbevestiging.
        </p>
    </div>
    <div class="form-field no-margin-bottom" *ngIf="takeOutDiscountApplies">
        <p class="red-text strong no-margin-bottom">
            Als u uw bestelling van minimaal 3 pizza's of pasta's afhaalt krijgt u op de pizza's en pasta's 10% korting.
        </p>
    </div>
    <div class="form-field no-margin-bottom">
        <label>&nbsp;</label>
        <label>
            <input type="radio" [checked]="!order.deliver" (click)="setDeliver()">Afhalen</label>
    </div>
    <div class="form-field no-margin-top" *ngIf="!noDeliveries">
        <label>&nbsp;</label>
        <label>
            <input type="radio" [checked]="order.deliver" (click)="setDeliver()">Bezorgen</label>
        <br>
        <div class="validation-message" *ngIf="noDeliveries">
            Door omstandigheden is het op dit moment helaas niet mogelijk om uw maaltijd te bezorgen.
            Excusses voor het ongemak.
        </div>
    </div>
    <p class="error" *ngIf="order.deliver && orderTimes.length === 1">
        Het is vandaag helaas niet meer mogelijk om uw bestelling te bezorgen.</p>
    <p class="error" *ngIf="!order.deliver && orderTimes.length === 1">
        Het is vandaag helaas niet meer mogelijk om uw bestelling af te halen.</p>
    <div class="form-field" *ngIf="order.deliver">
        <label>Straat en huisnummer *</label>
        <input type="text" [(ngModel)]="order.personInfo.streetAndNumber" (blur)="isValidStreetAndNumber()">
        <div class="validation-message" *ngIf="formValidation.streetAndNumber?.length > 0">
            {{formValidation?.streetAndNumber}}
        </div>
    </div>
    <div class="form-field" *ngIf="order.deliver">
        <label>Postcode *</label>
        <input type="text" [(ngModel)]="order.personInfo.postalCode" (blur)="isValidPostalCode()">
        <div class="validation-message" *ngIf="formValidation.postalCode?.length > 0">
            {{formValidation?.postalCode}}
        </div>
        <div class="validation-message" *ngIf="formValidation.deliveryCosts?.length > 0">
            {{formValidation?.deliveryCosts}}
        </div>
    </div>
    <div class="form-field" *ngIf="order.deliver">
        <label>Plaats *</label>
        <input type="text" [(ngModel)]="order.personInfo.city">
    </div>
    <div class="form-field">
        <label>Opmerkingen</label>
        <textarea [(ngModel)]="order.remarks"></textarea>
    </div>
    <div class="form-field no-margin-bottom">
        <p class="gray-text no-margin-bottom">
            Hieronder kunt u het bezorgmoment kiezen. Wij starten zo snel mogelijk met de bereiding van uw
            bestelling zodat wij zo spoedig mogelijk bij u kunnen zijn.
        </p>
    </div>
    <div class="form-field">
        <label *ngIf="order.deliver">Berzorging rond</label>
        <label *ngIf="!order.deliver">Afhaal rond</label>
        <select [(ngModel)]="order.deliveryDate" (change)="setOrderTimeConst()" class="small-margin-bottom">
            <option *ngFor="let item of orderDates" [selected]="order.deliveryDate === item" [value]="item">
                {{func.formatDateString(item)}}</option>
        </select>
        <select [(ngModel)]="order.deliveryTime" *ngIf="orderTimes.length > 1 && order.deliveryDate"
            (change)="checkIsValidForm()">
            <option *ngFor="let item of orderTimes" [selected]="order.deliveryTime === item">{{item}}</option>
        </select>
    </div>
    <div class="form-field">
        <p class="gray-text">
            Het is mogelijk dat uw gewenste bezorgtijd/afhaaltijd niet meer in de lijst staat.
            <br> Dit doen wij om de kwaliteit van onze producten en een tijdige bezorging/afhaal te kunnen garanderen.
            <br> U kunt altijd telefonisch contact opnemen om af te stemmen wat er mogelijk is.
            <br> Ons telefoonnummer is
            <a href="tel:038 465 4454">038 465 4454</a>.
        </p>
        <div class="validation-message" *ngIf="formValidation.requiredFields?.length > 0">
            {{formValidation?.requiredFields}}
        </div>
    </div>
</div>