export class MenuItem {
    constructor() {
        this.count = 1;
    }

    public id: number;
    public categoryId: number;
    public labelIT: string;
    public labelNL: string;
    public number: string;
    public name: string;
    public description: string;
    public ingredients: string;
    public page: number;
    public price: number;
    public count: number;
    public selectedOption: string;
    public options: string[];
    public showToppings: boolean;
    public extraTopping1: string;
    public extraTopping2: string;
    public extraTopping3: string;
    public remarks: string;

    public setSelectedOption() {
        if (this.options != null && this.options.length > 0) {
            this.selectedOption = this.options[0];
        }
    }

    public getExtraPrice(description: string) {
        const plusPriceRegex = /\(\+ ([0-9.]+)\)/gi;
        const plusPrice = plusPriceRegex.exec(description);
        if (plusPrice !== null) {
            return +plusPrice[1];
        }
        return 0;
    }

    public getPrice() {
        const itemPrice = this.getItemPrice();
        return this.count * itemPrice;
    }

    public getItemPrice() {
        let price = this.price;

        price += this.getExtraPrice(this.selectedOption);
        price += this.getExtraPrice(this.extraTopping1);
        price += this.getExtraPrice(this.extraTopping2);
        price += this.getExtraPrice(this.extraTopping3);

        return price;
    }
}

export class PersonInfo {
    constructor() {
        this.city = "Zwolle";
    }
    public name: string;
    public email: string;
    public phone: string;
    public streetAndNumber: string;
    public postalCode: string;
    public city: string;
}

export class Order {
    constructor() {
        this.personInfo = new PersonInfo();
        this.items = [];
        this.deliver = false;
        this.applyDeliveryCost = false;
        this.isPostalCodeOutOfZone = false;
        this.paymentType = "iDeal";
        this.deliveryDate = "";
        this.deliveryTime = "";
        this.totalPrice = 0;
        this.totalItemPrice = 0;
        this.remarks = "";
        this.discountApplied = false;
        this.discountPrice = 0;
        this.totalFoodItemCount = 0;
    }

    public personInfo: PersonInfo;
    public deliver: boolean;
    public items: Array<MenuItem>;
    public deliveryDate: string;
    public deliveryTime: string;
    public applyDeliveryCost: boolean;
    public isPostalCodeOutOfZone: boolean;
    public paymentType: string;
    public totalPrice: number;
    public totalItemPrice: number;
    public remarks: string;
    public discountApplied: boolean;
    public discountPrice: number;
    public totalFoodItemCount: number;
}



export class OrderResult {
    public orderId: number;
    public guid: string;
    public paymentType: string;
    public result: boolean;
}

export class ReservedSlots {
    public slots: DeliverySlot[];
    public isSiteClosed: boolean;
}

export class DeliverySlot {
    public deliveryDate: string;
    public deliveryTime: string;
    public reservedSlots: number;
}

export class OrderProcessInfo {
    public token: string;
    public menuItems: MenuItem[];
    public closedAfter: Date[];
    public openOn: Date[];
    public noDeliveries: boolean;
    public isSiteClosed: boolean;
    public closedOn: Date[];
    public noDiscountOn: Date[];
    public isDebugMode: boolean;
}

export class Topping {
    public groupLabel: string;
    public items: string[];
}

export const ToppingOptions: Topping[] = [{
    groupLabel: "Vlees",
    items: [
        "Ham (+ 2.50)",
        "Parmaham (+ 2.50)",
        "Pastrami (+ 2.50)",
        "Rosbief (+ 2.50)",
        "Rundergehakt (+ 2.50)",
        "Salami (+ 2.50)",
        "Scherpe Italiaanse salami (+ 2.50)",
        "Shoarma (+ 2.50)",
        "Spek (+ 2.50)"
    ]
}, {
    groupLabel: "Vis",
    items: [
        "Ansjovis (+ 2.50)",
        "Garnalen (+ 2.50)",
        "Gerookte zalm (+ 2.50)",
        "Mosselen (+ 2.50)",
        "Tonijn (+ 2.50)",
        "Zalm (+ 2.50)"
    ]
}, {
    groupLabel: "Italiaanse kaas",
    items: [
        "Gorgonzola (+ 2.50)",
        "Mozzarella (+ 2.50)",
        "Parmezaanse kaas (+ 2.50)",
        "Pizza kaas (+ 1.50)"
    ]
}, {
    groupLabel: "Ei",
    items: [
        "Ei (+ 1.50)",
    ]
}, {
    groupLabel: "Groente",
    items: [
        "Artisjokken (+ 1.50)",
        "Asperges (+ 1.50)",
        "Broccoli (+ 1.50)",
        "Champignons (+ 1.50)",
        "IJsbergsla (+ 1.50)",
        "Kappertjes (+ 1.50)",
        "Knoflook (+ 1.50)",
        "Olijven (+ 1.50)",
        "Oregano (+ 1.50)",
        "Paprika (+ 1.50)",
        "Rucola (+ 1.50)",
        "Spaanse pepers (+ 1.50)",
        "Tomaat (+ 1.50)",
        "Uien (+ 1.50)"
    ]
}, {
    groupLabel: "Fruit",
    items: [
        "Ananas (+ 1.50)",
        "Banaan (+ 1.50)",
    ]
}];
