<app-header></app-header>
<app-nav></app-nav>
<div id="content-wrapper">
  <div class="white-block">
    <h1>Bedankt voor uw bestelling</h1>
    <p>En natuurlijk smakelijk eten.</p>

    <p *ngIf="order.deliver == true">Bezorging rond: {{order.deliveryDate}} {{order.deliveryTime}}</p>
    <p *ngIf="order.deliver == false">Afhaal rond: {{order.deliveryDate}} {{order.deliveryTime}}</p>

    <div *ngIf="order.items.length > 0">
      <h1>Uw bestelling</h1>
      <app-order-list [currentOrder]="order" [showRemoveItem]="false"></app-order-list>
    </div>
  </div>
</div>
<app-footer></app-footer>
