<div id="order-details">
    <div class="order-detail header">
        <div class="row">
            <div class="name">Naam</div>
            <div class="count">Aantal</div>
            <div class="price">Prijs</div>
            <div class="rowPrice">Totaal</div>
            <div class="orderMin" *ngIf="showRemoveItem">&nbsp;</div>
        </div>
    </div>
    <div *ngFor="let item of order.items" class="order-detail body">
        <div class="itemProperties row">
            <div class="name">{{item.number}} {{item.name}} {{item.selectedOption}}</div>
            <div class="count">{{item.count}}</div>
            <div class="price">&euro; {{item.getItemPrice() | number:'1.2-2'}}</div>
            <div class="rowPrice">&euro; {{item.getPrice() | number:'1.2-2'}} </div>
            <div class="orderMin" *ngIf="showRemoveItem" (click)="onRemoveOrderItem(item)"><button>X</button></div>
        </div>
        <div class="break"></div>
        <div class="row extra" *ngIf="item.extraTopping1 || item.extraTopping2 || item.extraTopping3">
            Extra toppings: {{item.extraTopping1}} {{item.extraTopping2}} {{item.extraTopping3}}
        </div>
        <div class="row extra" *ngIf="item.remarks">
            Opmerking: {{item.remarks}}
        </div>
    </div>
    <div class="order-detail body" *ngIf="order.applyDeliveryCost && order.deliver && showDelivery">
        <div class="row">
            <div class="name">Bezorgkosten</div>
            <div class="count">1</div>
            <div class="price">&euro; {{deliveryCost | number:'1.2-2'}}</div>
            <div class="rowPrice">&euro; {{deliveryCost | number:'1.2-2'}}</div>
            <div class="orderMin" *ngIf="showRemoveItem">&nbsp;</div>
        </div>
    </div>
    <div class="order-detail body" *ngIf="!order.deliver && order.discountApplied">
        <div class="row">
            <div class="name">Korting op pizza's en pasta's</div>
            <div class="count">1</div>
            <div class="price">- &euro; {{order.discountPrice | number:'1.2-2'}}</div>
            <div class="rowPrice">- &euro; {{order.discountPrice | number:'1.2-2'}}</div>
            <div class="orderMin" *ngIf="showRemoveItem">&nbsp;</div>
        </div>
    </div>
    <div class="order-detail footer">
        <div class="row">
            <div class="name">Totaalbedrag</div>
            <div class="count">&nbsp;</div>
            <div class="rowPrice">
                &euro; {{order.totalPrice | number:'1.2-2'}}
            </div>
            <div class="orderMin" *ngIf="showRemoveItem">&nbsp;</div>
        </div>
    </div>
</div>
