<app-header></app-header>
<app-nav></app-nav>
<div id="content-wrapper">
    <div id="menu" class="white-block">

        <div *ngIf="isSiteClosed === true || currentPage == -1" style="text-align: center;">
            <h1>Online bestellen op dit moment niet mogelijk</h1>
            <p class="error">Het is op dit moment helaas niet mogelijk om online uw bestelling te plaatsen.</p>
        </div>

        <div *ngIf="currentPage == 0" style="text-align: center;">
            <img src="assets/images/loader.gif" alt="loading" />
        </div>

        <div *ngIf="currentPage == 1" class="order-page">
            <app-order-nav [showFloatingNav]="showFloatingNav" [isDisabledPrev]="true"
                [isDisabledNext]="order.totalPrice == 0" [showPrev]="true" (navNext)="navNext()" [showOrderList]="true"
                (navOrderList)="navOrderList()">
            </app-order-nav>
            <p class="error" *ngIf="isClosed === true">
                Op dit moment zijn wij (nog) niet geopend.
                <br>U kunt uw bestelling, voor een andere moment, al wel invoeren.</p>
            <div id="search">
                <input type="text" [(ngModel)]="searchString" (keyup)="searchMenuItems()"
                    placeholder="Zoeken op naam, nummer of ingredienten" />
            </div>
            <div class="categories">
                <div class="category" *ngFor="let cat of filteredMenuItems | groupBy: 'labelIT'">
                    <h1>{{cat.first.labelIT}}
                        <span>{{cat.first.labelNL}}</span>
                    </h1>
                    <div class="items page1">
                        <div class="item" *ngFor="let item of cat.value">
                            <div class="name">{{item.number}} {{item.name}}</div>
                            <div class="ingredients">{{item.ingredients}}</div>
                            <div class="options" *ngIf="item.options?.length > 0">
                                <select [(ngModel)]="item.selectedOption">
                                    <option *ngFor="let option of item.options" [value]="option">{{option}}</option>
                                </select>
                            </div>
                            <div class="price">&euro; {{item.price | number:'1.2-2' }}</div>
                            <div class="order">
                                <button class="showToppings" *ngIf="item.showToppings == true"
                                    (click)="showToppings(item, $event)">Toppings</button>
                                <button class="orderPlus"
                                    [disabled]="item.options?.length > 0 && item.selectedOption ==''"
                                    (click)="orderPlus(item, $event)">Toevoegen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="currentPage == 2" class="order-page">
            <app-order-nav [showFloatingNav]="showFloatingNav" (navNext)="navNext()" (navPrev)="navPrev()"
                [showOrderList]="true" (navOrderList)="navOrderList()">
            </app-order-nav>
            <div class="categories">
                <h1>Bevande
                    <span>Dranken</span>
                </h1>
                <div class="category" *ngFor="let cat of menuItemsPage2 | groupBy: 'labelIT'">
                    <h2>{{cat.first.labelIT}}</h2>
                    <div class="items page2">
                        <div class="item" *ngFor="let item of cat.value">
                            <div class="name">{{item.number}} {{item.name}}</div>
                            <div class="price">&euro; {{item.price | number:'1.2-2' }}</div>
                            <div class="order">
                                <button class="orderPlus" (click)="orderPlus(item, $event)">Toevoegen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="currentPage == 3" class="order-page">
            <app-order-nav [showFloatingNav]="showFloatingNav" [isDisabledNext]="!isReadyToOrder" (navNext)="navNext()"
                (navPrev)="navPrev()" [showOrderList]="true" (navOrderList)="navOrderList()">
            </app-order-nav>
            <h1>Uw gegevens</h1>
            <app-order-form></app-order-form>
        </div>

        <div *ngIf="currentPage == 4" class="order-page">
            <div class="order-nav">
                <button (click)="navPrev()">Vorige</button>
            </div>
            <h1>Uw bestelling</h1>
            <app-order-list></app-order-list>
            <h1>Betaalmethode</h1>
            <div id="form-payment" class="form-field">
                <label>
                    <input type="radio" [checked]="order.paymentType === 'iDeal'"
                        (click)="setPaymentType('iDeal')" />iDeal</label>
                <br>
                <label *ngIf="!order.deliver">
                    <input type="radio" [checked]="order.paymentType === 'Contant'"
                        (click)="setPaymentType('Contant')" />Contant / Pin</label>
                <span *ngIf="order.paymentType === 'Contant' && order.deliver" class="error">
                    Onze bezorgers hebben voor uw en onze veiligheid nooit meer dan 25 euro wisselgeld bij zich.
                </span>
                <span *ngIf="order.paymentType === ''" class="error">
                    Kies een betaalmethode om verder te gaan.
                </span>
            </div>
            <div class="order-nav bottom">
                <button [disabled]="isReadyToOrder === false || orderInProgress === true"
                    (click)="saveOrder()">Bestellen</button>
                <p class="error" *ngIf="orderFailed">Er is helaas iets fout gegaan bij het plaatsen van uw bestelling.
                    <br> Mocht u dat willen dan kunt u telefonisch contact op te nemen om zo de bestelling alsnog door
                    te geven. Ons telefoonnummer
                    is
                    <a href="tel:038 465 4454">038 465 4454</a>.
                    <br> Onze excuses voor het ongemak.</p>
            </div>
        </div>

        <div *ngIf="currentPage == 99" class="order-page">
            <div class="order-nav">
                <button (click)="navPrev()" class="button-doorgaan">Doorgaan met de bestelling</button>
            </div>
            <h1>Uw bestelling</h1>
            <app-order-list></app-order-list>
        </div>
    </div>
</div>
<div id="toppings-wrapper" *ngIf="showToppingsPanel == true">
    <div id="toppings-popup">
        <h1>Toppings</h1>
        <div class="item">
            <div class="name">{{toppingMenuItem.number}} {{toppingMenuItem.name}}</div>
            <div class="ingredients">{{toppingMenuItem.ingredients}}</div>
            <div class="price">&euro; {{toppingMenuItem.price | number:'1.2-2' }}</div>
            <hr>
            <h2>Extra toppings</h2>
            <div class="topping">
                <select [(ngModel)]="toppingMenuItem.extraTopping1" (change)="setToppingOptions()">
                    <option value=""></option>
                    <optgroup *ngFor="let group of toppingOptions1" label="{{group.groupLabel}}">
                        <option *ngFor="let item of group.items" [ngValue]="item">{{item}}</option>
                    </optgroup>
                </select>
            </div>
            <div class="topping">
                <select [(ngModel)]="toppingMenuItem.extraTopping2" (change)="setToppingOptions()">
                    <option value=""></option>
                    <optgroup *ngFor="let group of toppingOptions2" label="{{group.groupLabel}}">
                        <option *ngFor="let item of group.items" [ngValue]="item">{{item}}</option>
                    </optgroup>
                </select>
            </div>
            <div class="topping">
                <select [(ngModel)]="toppingMenuItem.extraTopping3" (change)="setToppingOptions()">
                    <option value=""></option>
                    <optgroup *ngFor="let group of toppingOptions3" label="{{group.groupLabel}}">
                        <option *ngFor="let item of group.items" [ngValue]="item">{{item}}</option>
                    </optgroup>
                </select>
            </div>
            <hr>
            <label class="small-text">Heeft u nog verdere wensen dan kunt u deze hieronder
                kwijt.</label>
            <textarea maxlength="100" class="remarks" [(ngModel)]="toppingMenuItem.remarks"></textarea>
        </div>
        <div class="toppings-buttons-wrapper">
            <button (click)="hideToppings()">Terug</button>
            <button class="button-ok" (click)="saveToppings($event)">Toevoegen</button>
        </div>
    </div>
</div>
<app-footer></app-footer>
<div *ngIf="isDebugMode" id="debug-notification">IS_DEBUG_MODE == TRUE</div>
