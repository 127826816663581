import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from "@angular/core";
import { Order } from "app/app.models";
import { GlobalFunctionsService } from "app/global-functions.service";
import { environment } from "environments/environment";
import { OrderService } from "app/order.service";
import { Subscription, interval } from "rxjs";

@Component({
    selector: "app-order-form",
    templateUrl: "./order-form.component.html",
    styleUrls: ["./order-form.component.less"]
})
export class OrderFormComponent implements OnInit, OnDestroy {
    orderDates: string[] = [];
    orderTimes: string[] = [];

    closingTime = environment.closingTime;
    deliveryCost = environment.deliveryCost;
    deliveryCostOrderAmmountLowerLimit = environment.deliveryCostOrderAmmountLowerLimit;
    maxReservedSlots = 3;

    formValidation: any = {};
    today = new Date();
    noDeliveries = false;
    order: Order;
    takeOutDiscountApplies = false;

    refresh$: Subscription;
    timer: ReturnType<typeof setTimeout>;
    timerInterval = (5*60*1000);

    constructor(
        public func: GlobalFunctionsService,
        private orderService: OrderService
    ) {
        this.order = orderService.order;
        this.noDeliveries = this.orderService.noDeliveries;
        if (this.noDeliveries && this.order.deliver) {
            this.setDeliver();
        }
    }

    refreshOrderDateConst() {
        console.log("Refreshing OrderDate")
        this.timer = setTimeout(() => {
            this.refreshOrderDateConst()
        }, this.timerInterval);
        this.setOrderDateConst();
    }

    ngOnInit() {
        this.timer = setTimeout(() => {
            this.refreshOrderDateConst()
        }, this.timerInterval);
        this.setOrderDateConst();
        this.checkIsValidForm();

        this.orderService.getReservedSlots().subscribe(() => {
            this.setOrderDateConst();
            this.checkIsValidForm();
        });

        this.refresh$ = interval(1000 * 10).subscribe(() => {
            this.orderService.getReservedSlots().subscribe(() => {
                this.setOrderDateConst();
                this.checkIsValidForm();
            });
        });
    }

    ngOnDestroy() {
        clearTimeout(this.timer);
        this.refresh$.unsubscribe();
    }

    setOrderDateConst() {
        this.orderDates = [];
        const currentDate = new Date();
        for (let index = 0; index < 7; index++) {
            let isMondayOpen = false;
            let skipDate = false;
            const newDate = currentDate.addDays(index);
            for (const closedOn of this.orderService.closedOn) {
                const closedOnDate = new Date(closedOn);
                if (closedOnDate.getMonth() === newDate.getMonth() && closedOnDate.getDate() === newDate.getDate()) {
                    skipDate = true;
                    break;
                }
            }
            if (skipDate) {
                continue;
            }
            for (const openOn of this.orderService.openOn) {
                const openOnDate = new Date(openOn);
                if (openOnDate.getDate() === newDate.getDate()) {
                    isMondayOpen = true;
                }
            }
            if (newDate.getDay() !== 1 || (newDate.getDay() === 1 && isMondayOpen)) {
                this.orderDates.push(this.func.formatDate(newDate));
                if (!this.order.deliveryDate) {
                    this.order.deliveryDate = this.orderDates[index];
                }
            }
        }
        this.setOrderTimeConst();
    }

    setOrderTimeConst() {
        this.orderTimes = [];
        if (this.func.stringIsNullOrEmpty(this.order.deliveryDate)) {
            return;
        }

        const deliveryDate = new Date(this.order.deliveryDate);
        let openHours = 16;
        let openMinutes = 30;
        for (const openOn of this.orderService.openOn) {
            const openOnDate = new Date(openOn);
            if (deliveryDate.getDate() === openOnDate.getDate()) {
                openHours = openOnDate.getHours();
                openMinutes = openOnDate.getMinutes();
            }
        }

        const reservedSlots = [];
        this.orderService.reservedDeliverySlots.forEach(slot => {
            if (
                slot.deliveryDate === this.order.deliveryDate &&
                slot.reservedSlots >= this.maxReservedSlots
            ) {
                reservedSlots.push(slot.deliveryTime);
            }
        });

        const startTime = new Date(deliveryDate);
        const endTime = new Date(deliveryDate);

        let prepTime = 45;
        startTime.setHours(openHours, openMinutes, 0);
        if (this.order.deliver) {
            endTime.setHours(this.closingTime.delivery.hour, this.closingTime.delivery.minute, 0);
        } else {
            endTime.setHours(this.closingTime.takeOut.hour, this.closingTime.takeOut.minute, 0);
        }

        if (this.orderService.closedAfter.length > 0) {
            this.orderService.closedAfter.forEach(dateString => {
                const date = new Date(dateString);
                if (deliveryDate.getDate() === date.getDate()) {
                    endTime.setHours(date.getHours(), date.getMinutes(), 0);
                    return;
                }
            });
        }

        if (
            deliveryDate.getDate() === this.today.getDate() &&
            this.today.getTime() > new Date(endTime).setMinutes(endTime.getMinutes() - 15)
        ) {
            return;
        }

        this.orderTimes = [""];
        const now = new Date();
        if (this.today.getDate() === deliveryDate.getDate() && now >= startTime) {
            let startMinutes = Math.round(now.getMinutes() / 15) * 15;
            let startHours = now.getHours();
            if (startMinutes === 60) {
                startHours += 1;
                startMinutes = 0;
            }
            startTime.setHours(startHours, startMinutes);
        }
        while (true) {
            const newTime = new Date(startTime);
            if (newTime.getHours() > 18) {
                prepTime = 45;
            } else {
                prepTime = 60;
            }
            newTime.setMinutes(newTime.getMinutes() + prepTime);
            if (newTime > endTime) {
                break;
            }
            const newTimeString =
                newTime.getHours() + ":" + (newTime.getMinutes() === 0 ? "00" : newTime.getMinutes());
            if (
                reservedSlots.indexOf(newTimeString) === -1 &&
                this.orderTimes.indexOf(newTimeString) === -1
            ) {
                this.orderTimes.push(newTimeString);
            }
            startTime.setMinutes(startTime.getMinutes() + 15);
        }
        if (this.orderTimes.indexOf(this.order.deliveryTime) === -1) {
            this.order.deliveryTime = "";
        }
        this.checkIsValidForm();
    }

    checkIsValidForm() {
        this.orderService.getTotalPrice();
        let hasErrors = false;
        if (!this.isValidRequiredFields()) {
            hasErrors = true;
        }
        if (!this.isValidPhone()) {
            hasErrors = true;
        }
        if (!this.isValidEmail()) {
            hasErrors = true;
        }
        if (this.order.deliver) {
            if (!this.isValidStreetAndNumber()) {
                hasErrors = true;
            }
            if (!this.isValidPostalCode()) {
                hasErrors = true;
            }
        }
        this.takeOutDiscountApplies = this.orderService.allowDiscountToday;
        this.orderService.orderFormIsValid = !hasErrors;
        this.orderService.checkIsReadyToOrder();
    }

    isValidStreetAndNumber() {
        if (
            this.order.personInfo.streetAndNumber &&
            this.order.personInfo.streetAndNumber.match(/.*\s[0-9]{1,}/gi) === null
        ) {
            this.formValidation.streetAndNumber = "Ongeldige adres opgegeven.";
            return false;
        } else {
            this.formValidation.streetAndNumber = "";
        }
        return true;
    }

    isValidPostalCode() {
        if (
            this.order.personInfo.postalCode &&
            this.order.personInfo.postalCode.match(/80[0-9]{2}\s?[a-z]{2}/gi) === null
        ) {
            this.formValidation.postalCode = "Ongeldige postcode opgegeven.";
            return false;
        } else {
            this.formValidation.postalCode = "";
        }

        const postalCodeWithoutDeliveryCost = [11, 12, 13, 14, 15, 16, 17, 19];
        const postalCodeWithDeliveryCost = [21, 22, 23, 24, 25, 31, 32, 33, 41, 42, 43];

        const postalCodeRegex = /80([0-9]{2})\s?[a-z]{2}/gi;
        const match = postalCodeRegex.exec(this.order.personInfo.postalCode);
        if (match === null || match.length === 0) {
            return false;
        }
        this.formValidation.deliveryCosts = "";
        if (postalCodeWithoutDeliveryCost.indexOf(+match[1]) === -1) {
            if (postalCodeWithDeliveryCost.indexOf(+match[1]) === -1) {
                this.formValidation.postalCode = "Wij bezorgen helaas niet op de postcode '" + match[0] + "'. U bent van harte welkom in onze zaak om uw bestelling af te komen halen.";
                return false;
            }
            this.formValidation.deliveryCosts =
                "Bezorging buiten de postcodes 8011 t/m 8017 en 8019 kost € " + Number(this.deliveryCost).toFixed(2) + " extra" +
                " bij een minimale bestelling van twee (2) gerechten en een minimum van € " +
                Number(this.deliveryCostOrderAmmountLowerLimit).toFixed(2) + ".";
            this.order.isPostalCodeOutOfZone = true;
        } else {
            if (this.order.totalItemPrice < this.deliveryCostOrderAmmountLowerLimit) {
                this.formValidation.deliveryCosts =
                    "Bij een bestelling onder de € " + Number(this.deliveryCostOrderAmmountLowerLimit).toFixed(2) +
                    ",- euro wordt € " + Number(this.deliveryCost).toFixed(2) + ",- euro bezorgenkosten gerekend.";
            }
            this.order.isPostalCodeOutOfZone = false;
        }
        return true;
    }

    isValidEmail() {
        if (
            this.order.personInfo.email &&
            this.order.personInfo.email.match(/.{2,}@.{3,}\..{2,}/gi) === null
        ) {
            this.formValidation.email = "Ongeldige e-mailadres opgegeven.";
            return false;
        } else {
            this.formValidation.email = "";
        }
        return true;
    }

    isValidPhone() {
        if (this.order.personInfo.phone && this.order.personInfo.phone.match(/0[0-9]{9}/g) === null) {
            this.formValidation.phone = "Ongeldig telefoonnummer opgegeven.";
            return false;
        } else {
            this.formValidation.phone = "";
        }
        return true;
    }

    isValidRequiredFields() {
        if (
            !(
                this.order.personInfo.name &&
                this.order.personInfo.phone &&
                (this.order.deliver === false ||
                    (this.order.personInfo.streetAndNumber &&
                        this.order.personInfo.postalCode &&
                        this.order.personInfo.city))
            )
        ) {
            this.formValidation.requiredFields = "Nog niet alle verplichte velden zijn ingevoerd.";
            return false;
        }
        this.formValidation.requiredFields = "";
        return true;
    }

    setDeliver() {
        if (this.noDeliveries) {
            this.order.deliver = false;
        }
        else {
            this.order.deliver = !this.order.deliver;
        }
        if (this.order.deliver) {
            this.order.paymentType = "iDeal";
        }
        this.setOrderTimeConst();
        this.orderService.checkIsReadyToOrder();
        this.checkIsValidForm();
    }
}
