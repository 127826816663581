<app-header></app-header>
<app-nav></app-nav>
<div id="content-wrapper">
  <div class="white-block">
    <h1>Bestelling niet gelukt</h1>
    <p>Er is helaas iets fout gegaan bij het verwerken van de bestelling.
      <br> Wij verzoeken u telefonisch contact op te nemen en zo de bestelling alsnog door te geven.
      <br>
      <br> Onze excuses voor het ongemak.</p>

    <div *ngIf="order.items.length > 0">
      <h1>Uw bestelling</h1>
      <app-order-list [currentOrder]="order" [showDelivery]="false" [showRemoveItem]="false"></app-order-list>
    </div>
  </div>
</div>
<app-footer></app-footer>
