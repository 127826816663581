<div class="white-block no-scroll">
  <h1>Menukaart</h1>
  <div class="placeholder">
    <img src="assets/images/folder/pagina01.jpg" id="menu pagina 1" alt="menu pagina 1" />
  </div>
  <div class="placeholder">
    <img src="assets/images/folder/pagina02.jpg" alt="menu pagina 2" *ngIf="showPage.indexOf(2) !== -1" />
  </div>
  <div class="placeholder">
    <img src="assets/images/folder/pagina03.jpg" alt="menu pagina 3" *ngIf="showPage.indexOf(3) !== -1" />
  </div>
  <div class="placeholder">
    <img src="assets/images/folder/pagina04.jpg" alt="menu pagina 4" *ngIf="showPage.indexOf(4) !== -1" />
  </div>
  <div class="placeholder">
    <img src="assets/images/folder/pagina05.jpg" alt="menu pagina 5" *ngIf="showPage.indexOf(5) !== -1" />
  </div>
  <div class="placeholder">
    <img src="assets/images/folder/pagina06.jpg" alt="menu pagina 6" *ngIf="showPage.indexOf(6) !== -1" />
  </div>
  <div class="placeholder">
    <img src="assets/images/folder/pagina07.jpg" alt="menu pagina 7" *ngIf="showPage.indexOf(7) !== -1" />
  </div>
  <div class="placeholder">
    <img src="assets/images/folder/pagina08.jpg" alt="menu pagina 8" *ngIf="showPage.indexOf(8) !== -1" />
  </div>
</div>