import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Order, MenuItem } from "app/app.models";
import { environment } from "environments/environment";
import { OrderService } from "app/order.service";

@Component({
  selector: "app-order-list",
  templateUrl: "./order-list.component.html",
  styleUrls: ["./order-list.component.less"]
})
export class OrderListComponent implements OnInit {
  @Input() showDelivery = true;
  @Input() currentOrder = null;
  @Input() showRemoveItem = true;

  deliveryCost = environment.deliveryCost;
  order: Order;

  constructor(private orderService: OrderService) {
    this.order = orderService.order;
  }

  ngOnInit() {
    if (this.currentOrder) {
      this.order = this.currentOrder;
    }
  }

  onRemoveOrderItem(orderItem: MenuItem) {
    this.orderService.orderMin(orderItem);
  }
}
