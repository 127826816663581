import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Order } from "app/app.models";
import { OrderService } from "app/order.service";

@Component({
  selector: "app-order-nav",
  templateUrl: "./order-nav.component.html",
  styleUrls: ["./order-nav.component.less"]
})
export class OrderNavComponent implements OnInit {
  @Input() showFloatingNav: boolean;
  @Input() showPrev = true;
  @Input() showNext = true;
  @Input() showOrderList = false;
  @Input() isDisabledPrev = false;
  @Input() isDisabledNext = false;

  @Output() navPrev: EventEmitter<any> = new EventEmitter();
  @Output() navNext: EventEmitter<any> = new EventEmitter();
  @Output() navOrderList: EventEmitter<any> = new EventEmitter();

  order: Order;

  constructor(private orderService: OrderService) {
    this.order = orderService.order;
  }

  ngOnInit() {}

  onNavPrev() {
    this.navPrev.emit();
  }

  onNavNext() {
    this.navNext.emit();
  }

  onNavOrderList() {
    this.navOrderList.emit();
  }
}
