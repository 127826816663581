// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    apiBaseUrl: "/api",
    deliveryCost: 7.5,
    deliveryCostOrderAmmountLowerLimit: 17.50,
    orderRemarks: "TEST",
    closingTime: {
        delivery: { hour: 20, minute: 30 },
        takeOut: { hour: 21, minute: 0 }
    }
};
