import { throwError as observableThrowError, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { Observable } from "rxjs";

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
    MenuItem,
    Order,
    OrderResult,
    OrderProcessInfo,
    ReservedSlots,
} from "app/app.models";

import { environment } from "environments/environment";

@Injectable()
export class DataService {
    baseUrl = environment.apiBaseUrl;
    token = "";

    constructor(private http: HttpClient) {}

    private handleError(error: any): Observable<any> {
        if (!environment.production) {
            console.error("An error occurred.", error);
        }
        return throwError(() => error || "Server Error");
    }

    public getOrderItems(
        orderId: number,
        orderGuid: string
    ): Observable<MenuItem[]> {
        return this.http
            .get<MenuItem[]>(
                this.baseUrl + `/orderItems/${orderId}/${orderGuid}`
            )
            .pipe(catchError((err) => this.handleError(err)));
    }

    public getOrder(orderId: number, orderGuid: string): Observable<Order> {
        return this.http
            .get<Order>(this.baseUrl + `/order/${orderId}/${orderGuid}`)
            .pipe(
                map((result) => {
                    const order = result;
                    const items = order.items;
                    order.items = [];
                    items.forEach((element) => {
                        order.items.push(
                            Object.assign(new MenuItem(), element)
                        );
                    });
                    return order;
                }),
                catchError((err) => this.handleError(err))
            );
    }

    public prepareIdealPayment(
        orderId: number,
        guid: string
    ): Observable<string> {
        return this.http
            .get(this.baseUrl + `/onlinekassa/start/${orderId}/${guid}`, {
                responseType: "text",
            })
            .pipe(catchError((err) => this.handleError(err)));
    }

    public getOrderProcessInfo(): Observable<OrderProcessInfo> {
        return this.http
            .get<OrderProcessInfo>(this.baseUrl + "/orderProcessInfo")
            .pipe(
                tap((data: OrderProcessInfo) => {
                    this.token = data.token;
                    const menuItems: MenuItem[] = [];
                    data.menuItems.forEach((element) => {
                        menuItems.push(Object.assign(new MenuItem(), element));
                    });
                    data.menuItems = menuItems;
                    return data;
                })
            )
            .pipe(catchError((err) => this.handleError(err)));
    }

    public getReservedSlots(): Observable<ReservedSlots> {
        return this.http
            .get<ReservedSlots>(this.baseUrl + "/reservedSlots")
            .pipe(
                tap((data: ReservedSlots) => {
                    return data;
                })
            )
            .pipe(catchError((err) => this.handleError(err)));
    }

    public saveOrder(order: Order): Observable<OrderResult> {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http
            .post<OrderResult>(this.baseUrl + "/saveOrder", order, { headers })
            .pipe(catchError((err) => this.handleError(err)));
    }
}
