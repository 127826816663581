import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-over-ons",
  templateUrl: "./over-ons.component.html",
  styleUrls: ["./over-ons.component.less"]
})
export class OverOnsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
