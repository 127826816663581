import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";

import { CookieService } from "ngx-cookie-service";
import { GoogleAnalyticsEventsService } from "./google-analytics-events.services";
import { OrderService } from "./order.service";

import { AppComponent } from "./app.component";
import { DataService } from "./data.service";
import { HomeComponent } from "./home/home.component";
import { OrderComponent } from "./order/order.component";
import { OverOnsComponent } from "./over-ons/over-ons.component";
import { MenuFolderComponent } from "./menu-folder/menu-folder.component";
import { GroupByPipe } from "./group-by.pipe";
import { NavComponent } from "./nav/nav.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { OrderTnxComponent } from "./order-tnx/order-tnx.component";
import { OrderSorryComponent } from "./order-sorry/order-sorry.component";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { OrderListComponent } from "./order-list/order-list.component";
import { OrderFormComponent } from "./order/order-form/order-form.component";
import { GlobalFunctionsService } from "app/global-functions.service";
import { OrderNavComponent } from "./order/order-nav/order-nav.component";

const appRoutes: Routes = [
  { path: "home", component: HomeComponent, pathMatch: "full" },
  { path: "order/:id", component: OrderComponent, pathMatch: "full" },
  { path: "order", component: OrderComponent, pathMatch: "full" },
  { path: "over-ons", component: OverOnsComponent, pathMatch: "full" },
  { path: "order-tnx/:id/:guid", component: OrderTnxComponent, pathMatch: "full" },
  { path: "order-tnx", component: OrderTnxComponent, pathMatch: "full" },
  { path: "order-sorry/:id/:guid", component: OrderSorryComponent, pathMatch: "full" },
  { path: "order-sorry", component: OrderSorryComponent, pathMatch: "full" },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "/home",
    pathMatch: "full"
  }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    OrderComponent,
    OverOnsComponent,
    MenuFolderComponent,
    NavComponent,
    FooterComponent,
    HeaderComponent,
    OrderTnxComponent,
    OrderSorryComponent,
    GroupByPipe,
    SafeHtmlPipe,
    OrderListComponent,
    OrderFormComponent,
    OrderNavComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true }),
    BrowserModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    DataService,
    CookieService,
    GlobalFunctionsService,
    GoogleAnalyticsEventsService,
    OrderService,
    {
      provide: APP_INITIALIZER,
      useFactory: (orderService: OrderService) => () => orderService.getOrderProcessInfo(),
      multi: true,
      deps: [OrderService]
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
