import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Order } from "app/app.models";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "app/data.service";
import { OrderService } from "app/order.service";

@Component({
    selector: "app-order-tnx",
    templateUrl: "./order-tnx.component.html",
    styleUrls: ["./order-tnx.component.less"],
    encapsulation: ViewEncapsulation.None
})
export class OrderTnxComponent implements OnInit {
    private routeSubject: any = new Subject();
    order$: Observable<Order>;
    order = new Order();

    constructor(private route: ActivatedRoute,
                private dataService: DataService,
                private orderService: OrderService) { }

    ngOnInit() {
        this.routeSubject = this.route.params.subscribe(params => {
            const orderId = params.id;
            const orderGuid = params.guid;
            if (!orderId || !orderGuid) {
                return;
            }
            this.order$ = this.dataService.getOrder(orderId, orderGuid);
            this.order$.subscribe(data => {
                if (data) {
                    this.order = data;
                    this.orderService.order.items = [];
                }
            });
        });
    }
}
