import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject ,  Observable } from "rxjs";
import { DataService } from "app/data.service";
import { MenuItem, Order } from "app/app.models";

@Component({
  selector: "app-order-sorry",
  templateUrl: "./order-sorry.component.html",
  styleUrls: ["./order-sorry.component.less"],
  encapsulation: ViewEncapsulation.None
})
export class OrderSorryComponent implements OnInit {
  private routeSubject: any = new Subject();
  order$: Observable<Order>;
  order = new Order();

  constructor(private route: ActivatedRoute, private dataService: DataService) {}

  ngOnInit() {
    this.routeSubject = this.route.params.subscribe(params => {
      const orderId = params.id;
      const orderGuid = params.guid;
      if (!orderId || !orderGuid) {
        return;
      }
      this.order$ = this.dataService.getOrder(orderId, orderGuid);
      this.order$.subscribe(data => {
        if (data) {
          this.order = data;
        }
      });
    });
  }
}
