import { Component, ElementRef, OnInit, OnDestroy, AfterViewInit } from "@angular/core";

import { DataService } from "app/data.service";
import {
    MenuItem,
    Order,
    DeliverySlot,
    Topping,
    ToppingOptions
} from "app/app.models";
import { Subject, Subscription, fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { CookieService } from "ngx-cookie-service";
import { ActivatedRoute, Router } from "@angular/router";
import { GoogleAnalyticsEventsService } from "app/google-analytics-events.services";
import { OrderService } from "../order.service";
import { environment } from "environments/environment";

declare var _: any;

@Component({
    selector: "app-order",
    templateUrl: "./order.component.html",
    styleUrls: ["./order.component.less"]
})
export class OrderComponent implements OnInit, OnDestroy, AfterViewInit {

    predefinedOrder: MenuItem[] = [];
    predefinedOrderHaveBeenProcessed = false;
    private routeSubject: any = new Subject();
    subscriptionScroll: Subscription;

    searchString = "";

    filteredMenuItems: MenuItem[] = [];
    allMenuItems: MenuItem[] = [];
    menuItemsPage1: MenuItem[] = [];
    menuItemsPage2: MenuItem[] = [];
    today = new Date();

    currentPage = 0;
    previousPage = 0;
    showFloatingNavOnHeight = 200;

    searchMenuItemsSubject: Subject<string> = new Subject();

    orderInProgress = false;
    orderFailed = false;
    showFloatingNav = false;

    order: Order;

    closedAfter: Date[];
    closedOn: Date[];
    openOn: Date[];
    reservedDeliverySlots: DeliverySlot[];

    isReadyToOrder = false;
    isDebugMode = false;
    isClosed = false;
    isSiteClosed = false;

    showToppingsPanel = false;
    toppingMenuItem: MenuItem;
    toppingOptions1: Topping[];
    toppingOptions2: Topping[];
    toppingOptions3: Topping[];

    constructor(
        private dataService: DataService,
        private cookies: CookieService,
        private route: ActivatedRoute,
        private router: Router,
        private element: ElementRef,
        private ga: GoogleAnalyticsEventsService,
        private orderService: OrderService,
    ) {
        this.order = this.orderService.order;
        this.closedAfter = orderService.closedAfter;
        this.closedOn = orderService.closedOn;
        this.openOn = orderService.openOn;
        this.reservedDeliverySlots = orderService.reservedDeliverySlots;


        orderService.isReadyToOrder$.subscribe(result => {
            this.isReadyToOrder = result;
        });
        orderService.isSiteClosed$.subscribe(result => {
            this.isSiteClosed = result;
            if (this.isSiteClosed === true) {
                this.currentPage = -1;
            }
        });
        this.isDebugMode = orderService.isDebugMode;
    }

    ngOnInit() {
        this.getPersonInfoCookie();
        this.getOrderIdentifierFromRoute();
        this.initSearchMenuItemsSubject();
        this.isClosed = this.orderService.isClosed;
        this.setMenuItems();
    }

    filterToppings(selectedvalues: string[]) {
        const result: Topping[] = [];
        ToppingOptions.forEach(group => {
            const toppings = _.filter(group.items, (topping: string) => {
                return !(selectedvalues.includes(topping));
            });
            if (toppings.length > 0) {
                result.push({
                    groupLabel: group.groupLabel,
                    items: toppings
                });
            }
        });
        return result;
    }

    setToppingOptions() {
        this.toppingOptions1 = this.filterToppings([this.toppingMenuItem.extraTopping2, this.toppingMenuItem.extraTopping3]);
        this.toppingOptions2 = this.filterToppings([this.toppingMenuItem.extraTopping1, this.toppingMenuItem.extraTopping3]);
        this.toppingOptions3 = this.filterToppings([this.toppingMenuItem.extraTopping1, this.toppingMenuItem.extraTopping2]);
    }

    setMenuItems(): void {
        this.allMenuItems = this.orderService.allMenuItems;
        if (this.allMenuItems.length === 0) {
            this.currentPage = -1;
        } else {
            this.menuItemsPage1 = _.filter(this.allMenuItems, { page: 1 });
            this.menuItemsPage2 = _.filter(this.allMenuItems, { page: 2 });
            this.filteredMenuItems = this.menuItemsPage1;
            if (this.currentPage === 0) {
                this.currentPage = 1;
            }
            this.processPredifinedOrder();
            this.orderService.getTotalPrice();
        }
    }

    processPredifinedOrder(): void {
        if (
            this.allMenuItems.length > 0 &&
            this.predefinedOrder.length > 0 &&
            !this.predefinedOrderHaveBeenProcessed
        ) {
            this.predefinedOrderHaveBeenProcessed = true;
            this.predefinedOrder.forEach(item => {
                const currentItem = this.allMenuItems.find(x => x.id === item.id);
                currentItem.count = item.count;
                this.orderPlus(currentItem, null);
            });
        }
    }

    ngAfterViewInit(): void {
        this.subscriptionScroll = fromEvent(this.element.nativeElement.parentNode, "scroll").pipe(
            debounceTime(100)
        ).subscribe(() => this.onScroll());
    }

    ngOnDestroy(): void {
        if (this.routeSubject) {
            this.routeSubject.unsubscribe();
        }
        if (this.subscriptionScroll) {
            this.subscriptionScroll.unsubscribe();
        }
    }

    onScroll() {
        this.showFloatingNav =
            this.element.nativeElement.parentNode.scrollTop > this.showFloatingNavOnHeight;
    }

    onNav(action: string) {
        this.ga.emitEvent("Order", "Page", action, this.currentPage);
        this.cookies.set("personInfo", JSON.stringify(this.orderService.order.personInfo), 360);

        if (this.element.nativeElement.parentNode.scrollTop > this.showFloatingNavOnHeight) {
            if (this.element.nativeElement.parentNode.scrollTo !== undefined) {
                this.element.nativeElement.parentNode.scrollTo(0, 186);
            }
        }
        this.orderService.checkIsReadyToOrder();
    }

    navNext() {
        this.onNav("Next");
        this.currentPage = this.currentPage + 1;
    }

    navPrev() {
        this.onNav("Prev");
        if (this.currentPage === 99) {
            this.currentPage = this.previousPage;
            return;
        }
        if (this.currentPage > 1) {
            this.currentPage = this.currentPage - 1;
        }
    }

    navOrderList() {
        this.orderService.getTotalPrice();
        this.orderService.checkIsReadyToOrder();

        this.previousPage = this.currentPage;
        this.currentPage = 99;
    }

    private getOrderIdentifierFromRoute() {
        this.routeSubject = this.route.params.subscribe(params => {
            const orderIdentifier = params.id;
            if (!orderIdentifier) {
                return;
            }
            const output = orderIdentifier.toLowerCase().split(/([a-z]{1,2})/gi);
            for (let index = 0; index < output.length; index = index + 2) {
                if (!output[index] || !output[index + 1]) {
                    break;
                }
                const newMenuItem = new MenuItem();
                newMenuItem.id = +output[index];
                newMenuItem.count = output[index + 1].charCodeAt(0) - 96;
                this.predefinedOrder.push(newMenuItem);
            }
            this.processPredifinedOrder();
        });
    }

    getOrder() {
        this.orderService.order.items = [];
        const result: MenuItem[] = [];
        if (this.allMenuItems.length > 0) {
            this.allMenuItems.forEach((item, id) => {
                if (item.count > 0) {
                    this.orderService.order.items.push(item);
                }
            });
            this.orderService.getTotalPrice();
        }
        return this.orderService.order.items;
    }

    public setPaymentType(type: string) {
        this.orderService.order.paymentType = type;
    }

    saveOrder() {
        this.orderInProgress = true;
        this.dataService.saveOrder(this.orderService.order).subscribe(
            orderResult => {
                if (orderResult == null || orderResult.result === false) {
                    this.orderFailed = true;
                    console.log(orderResult);
                } else {
                    this.ga.emitEvent("Order", "SaveOrder");
                    if (orderResult.paymentType === "iDeal") {
                        this.dataService
                            .prepareIdealPayment(orderResult.orderId, orderResult.guid)
                            .subscribe(paymentUrl => {
                                window.location.href = paymentUrl;
                            });
                    } else {
                        this.router.navigateByUrl(`/order-tnx/${orderResult.orderId}/${orderResult.guid}`);
                    }
                }
            },
            error => {
                this.orderFailed = true;
                console.log(error);
            }
        );
    }

    allesWeergeven() {
        this.searchString = "";
        this.filteredMenuItems = this.menuItemsPage1;
    }

    changeOrderButtonText(event: any) {
        if (!event) {
            return;
        }
        const target = event.target || event.srcElement || event.currentTarget;
        target.textContent = "+1";
        setTimeout(() => {
            target.textContent = "Toevoegen";
        }, 1000);
    }

    orderPlus(newItem: MenuItem, event: any) {
        const orderItem = _.find(this.orderService.order.items, (i: MenuItem) => {
            return i.id === newItem.id
                && i.selectedOption === newItem.selectedOption
                && i.extraTopping1 === newItem.extraTopping1
                && i.extraTopping2 === newItem.extraTopping2
                && i.extraTopping3 === newItem.extraTopping3
                && i.remarks === newItem.remarks;
        });
        if (orderItem === undefined) {
            newItem.count = newItem.count !== 0 ? newItem.count : 1;
            const convertedItem = Object.assign(new MenuItem(), newItem);
            convertedItem.ingredients = "";
            convertedItem.description = "";
            this.orderService.order.items.push(convertedItem);
        } else {
            orderItem.count = orderItem.count + 1;
        }
        this.orderService.getTotalPrice();
        this.changeOrderButtonText(event);
    }

    private getPersonInfoCookie() {
        if (this.cookies.check("personInfo")) {
            this.orderService.order.personInfo = JSON.parse(this.cookies.get("personInfo"));
            if (!this.orderService.order.remarks) {
                this.orderService.order.remarks = environment.orderRemarks;
            }
            this.orderService.getTotalPrice();
        }
    }

    initSearchMenuItemsSubject() {
        this.searchMenuItemsSubject.pipe(debounceTime(400)).subscribe(searchString => {
            if (!searchString) {
                this.filteredMenuItems = this.menuItemsPage1;
                return;
            }
            this.filteredMenuItems = this.menuItemsPage1.filter(item => {
                const searchFor = this.searchString.toLowerCase().trim();
                const searchForArray = searchFor.split(" ");
                let result = 0;
                const searchInArray: string[] = [];
                searchInArray.push(item.number);
                searchInArray.push(item.ingredients);
                searchInArray.push(item.name);
                searchInArray.push(item.labelIT);
                searchInArray.push(item.labelNL);
                if (item.description) {
                    searchInArray.push(item.description);
                }
                for (const searchTerm of searchForArray) {
                    if (searchInArray.contains(searchTerm).length > 0) {
                        result++;
                    }
                }
                return result === searchForArray.length;
            });
        });
    }

    searchMenuItems() {
        this.searchMenuItemsSubject.next(this.searchString);
    }

    showToppings(newItem: MenuItem, event: any) {
        this.toppingMenuItem = { ...newItem } as MenuItem;
        this.setToppingOptions();
        this.showToppingsPanel = true;
    }

    saveToppings(event: any) {
        this.orderPlus(this.toppingMenuItem, event);
        setTimeout(() => {
            this.toppingMenuItem = null;
            this.showToppingsPanel = false;
        }, 1000);
    }

    hideToppings() {
        this.toppingMenuItem = null;
        this.showToppingsPanel = false;
    }
}
