import { Component, OnInit, OnDestroy, AfterViewInit, Input } from "@angular/core";
import { fromEvent, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";


@Component({
    selector: "app-menu-folder",
    templateUrl: "./menu-folder.component.html",
    styleUrls: ["./menu-folder.component.less"]
})
export class MenuFolderComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() scrollPosition: number;

    subscriptionScroll: Subscription;

    showPage: number[] = [];

    constructor() { }

    ngOnInit() { }

    ngAfterViewInit(): void {
        this.subscriptionScroll = fromEvent(document.getElementById("container"), "scroll").pipe(
            debounceTime(100)
        ).subscribe(() => this.onScroll());
    }

    ngOnDestroy(): void {
        if (this.subscriptionScroll) {
            this.subscriptionScroll.unsubscribe();
        }
    }

    onScroll() {
        let pageHeight = 1096;
        if (window.innerWidth < 520) {
            pageHeight = Math.ceil((window.innerWidth - 20) / 500 * 1001);
        }
        const position = document.getElementById("container").scrollTop;
        const showLastPageNumber = Math.ceil(position / pageHeight) + 2;

        for (let i = 2; i < showLastPageNumber; i++) {
            if (this.showPage.indexOf(i) === -1) {
                this.showPage.push(i);
            }
        }
    }
}
