<div class="floating-nav" *ngIf="showFloatingNav">
  <div class="order-nav">
    <button *ngIf="showPrev" [disabled]="isDisabledPrev" (click)="onNavPrev()" class="button-prev">Vorige</button>
    <button *ngIf="showNext" [disabled]="isDisabledNext" (click)="onNavNext()" class="button-next">Volgende</button>
    <button *ngIf="showOrderList" [disabled]="order.totalItemPrice === 0" (click)="onNavOrderList()" class="button-order">&euro; {{order.totalPrice | number:'1.2-2'}}</button>
  </div>
</div>
<div class="order-nav">
  <button *ngIf="showPrev" [disabled]="isDisabledPrev" (click)="onNavPrev()" class="button-prev">Vorige</button>
  <button *ngIf="showNext" [disabled]="isDisabledNext" (click)="onNavNext()" class="button-next">Volgende</button>
  <button *ngIf="showOrderList" [disabled]="order.totalItemPrice === 0" (click)="onNavOrderList()" class="button-order">&euro; {{order.totalPrice | number:'1.2-2'}}</button>
</div>
